import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "designer"
};
import DesignerBanner from "@/views/digitalCustom/designer-module/designer-banner.vue";
import DesignersItem from "@/views/digitalCustom/designer-module/designersItem.vue";
export default {
  __name: 'designer',
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(DesignerBanner), _createVNode(DesignersItem)]);
    };
  }
};